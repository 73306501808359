import { forEach } from "lodash";
import enums from "../Utilities/Enums/Enums";
import util from "../Utilities/Functions/Functions";

export function underscoreDocuments(docs) {
  if (!util.isObjEmpty(docs.policyDocuments)) {
    forEach(docs.policyDocuments, doc => {
      doc.fileName = removeExtAndSpaces(doc.fileName);
    });
  }
  if (!util.isObjEmpty(docs.nonPolicyDocuments)) {
    forEach(docs.nonPolicyDocuments, doc => {
      doc.fileName = removeExtAndSpaces(doc.fileName);
    });
  }
  return docs;
}

export function addToInitialValues(documents, initialFormValues) {
  if (!util.isObjEmpty(documents)) {
    if (!util.isObjEmpty(documents.policyDocuments)) {
      forEach(documents.policyDocuments, doc => {
        initialFormValues[removeExtAndSpaces(doc.fileName)] = enums.bool.false;
      });
    }
    if (!util.isObjEmpty(documents.nonPolicyDocuments)) {
      forEach(documents.nonPolicyDocuments, doc => {
        initialFormValues[removeExtAndSpaces(doc.fileName)] = enums.bool.false;
      });
    }
  }
  return initialFormValues;
}

export function addToInitialRepeatingItemValues(documents, initialFormValues) {
  if (!util.isObjEmpty(documents)) {
    forEach(documents, doc => {
      let fileName = doc.repeatingItemName
      if (doc.repeatingItemStartDate) {
        fileName += ": " + doc.repeatingItemStartDate;
      }
      if (doc.repeatingItemEndDate) {
        fileName += " - " + doc.repeatingItemEndDate;
      }
      if (!doc.repeatingItemStartDate && !doc.repeatingItemEndDate) {
        if (doc.repeatingItemAddress) {
          fileName += " - " + doc.repeatingItemAddress;
        }
      }
      initialFormValues[fileName] = enums.bool.false;
    });
  }
  return initialFormValues;
}

function removeExtAndSpaces(fileName) {
  let modifiedFileName = fileName;
  let hasExtensionAt = fileName.indexOf(".");
  if (hasExtensionAt > -1) {
    modifiedFileName = fileName.substring(0, hasExtensionAt);
  }
  let normalString = modifiedFileName.replace(/ /g, "_");
  return normalString;
}

export function updatePolicyList(newSubmissionData, policies) {
  for (let i = 0; i < policies.length; i++) {
    if (policies[i].submissionID.toUpperCase() === newSubmissionData.submissionID.toUpperCase() || policies[i].submissionNumber === newSubmissionData.submissionNumber) {
      policies[i] = newSubmissionData;
      break;
    }
  }
  return policies;
}
