import React, { useRef, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FieldWrapper } from "../../../../StylizedComponents/index";
import { Field } from "formik";
import LabelCommon from "../Shared/LabelCommon";
import NestedFieldAlert from "../Alerts/NestedFieldAlert";
import { Container, Row, Col } from "react-bootstrap";
import ps from "../../../Functions/PageScript";
import enums from "../../../Enums/Enums";
import util from "../../../Functions/Functions";
import ErrorMessage from "../../../../Errors/ErrorMessage/ErrorMessage";

const DateField = (props) => {
  const {
    checkDateLessThanAIs,
    clearPropertyValueFromPSField,
    columnOffset,
    contingentPSBundle,
    defaultValue,
    disabled,
    field: { name, value },
    fieldsRef,
    form: { errors, touched, setErrors, setFieldTouched, setFieldValue, values },
    helptext,
    id,
    isAdmin,
    label,
    label_css,
    label_location,
    label_prefix,
    minDate,
    placeholder,
    popover_class,
    popover_placement,
    setFormValueCallback,
    setValueParentOrLocal,
    static_col_breakpoint,
    type,
    validateQuestionSimple
  } = props;

  //#region stored values

  const app = useSelector((state) => state.app);
  const dateConflictAlert = useSelector((state) => state.questions.dateConflictAlert);
  const dateConflictAlertAI = useSelector((state) => state.questions.dateConflictAlertAI);
  const questions = useSelector((state) => state.questions);
  const isQuestionsContext = ps.isQuestionsContext(questions);
  const storedInvalidFields = useSelector((state) => state.questions.storedInvalidFields);
  const _dateField = useRef(null);

  //DatePicker's react-datepicker-ignore-onclickoutside clears away the has-error class from the input if we try to set in JSX;
  //in order to set has-error, have to use state and pass it to the Datepicker div wrapper so the class survives: validtionState
  const [dateValue, setDate] = useState(defaultValue && !value ? new Date(defaultValue) : value ? new Date(value) : "");
  const [localInvalidDateWarning, setInvalidDate] = useState("");
  const [invalidDateWarning, setInvalidDateWarning] = useState("");

  let hasError = util.checkFieldHasQuestionError(name, storedInvalidFields);
  const aiMinDate = getAIMinDate();
  const newMinDate = questions.pageUrl.indexOf("AddlInsured") > 0 ? aiMinDate : minDate;

  //#endregion stored values

  //#region pageScript

  const normalizedId = ps.removeSetIDAndOPFromLookupCode(id);
  let psPremount, psExecutePostmount, psPostmountExecutedFields;
  let psReady = {};

  if (isQuestionsContext) {
    psPremount = ps.getPSUpdates(questions.pageScript.scripts, enums.psExecute.premount, normalizedId, id);
    psExecutePostmount = ps.getPSExecutable(questions.pageScript.scripts, enums.psExecute.postmount, id);
    psPostmountExecutedFields = ps.getPSUpdates(questions.psExecutedFields, enums.psExecute.postmount, normalizedId, id);

    if (psPremount && psPremount.length > 0) {
      psReady = ps.applyPS(psPremount, psReady, values[name]);
    }

    if (psPostmountExecutedFields && psPostmountExecutedFields.length > 0) {
      psReady = ps.applyPS(psPostmountExecutedFields, psReady, values[name]);

      if (psReady.value && psReady.value !== undefined && psReady.value !== values[name]) {
        if (psReady.value === "clearValue") {
          clearPropertyValueFromPSField(psReady.id, "value");
          setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
        }
        else {
          setValueParentOrLocal(name, psReady.value, setFieldValue, setFormValueCallback);
        }
      }

      if (ps.hasActiveAlert(psReady)) {
        //need this to mark up input and override valid
        hasError = true;
        // hasValid = false;
      }
    }
  }

  //#endregion pageScript

  //#region functions & actions

  if (dateConflictAlert && dateConflictAlert !== localInvalidDateWarning) {
    setInvalidDate(dateConflictAlert);
    setInvalidDateWarning(dateConflictAlert);
  }

  //resides here because PS can't get values from subsequent pages
  //(e.g. error triggers on Coverages, but we don't have access to AIs)
  if (dateConflictAlertAI && dateConflictAlertAI !== localInvalidDateWarning) {
    setInvalidDate(dateConflictAlertAI);
    setInvalidDateWarning(dateConflictAlertAI);
  }

  //handlers
  const deleteError = (errors, name) => {
    errors = delete errors[name];
    setErrors(errors);
  };

  const handlePS = (formattedValue) => {
    if (psExecutePostmount && formattedValue) {
      contingentPSBundle(psExecutePostmount, formattedValue, name, enums.psExecute.postmount);
    }
  };

  const handleCheckDateLessThanAIs = (formattedDate) => {
    if (questions.pageUrl.indexOf("AddlInsured") < 0 && !util.isObjEmpty(app.currentSubmission)) {
      checkDateLessThanAIs(formattedDate, questions.submissionID, questions.programCode);
    }
  };

  const handleBlur = (date) => {
    if (date === undefined) {
      //this is only way to clear DP
      //https://github.com/Hacker0x01/react-datepicker/issues/754
      setValueParentOrLocal(name, "", setFieldValue, setFormValueCallback);
      handlePS("");
      setDate(date);
    }
    else if (date && moment(date, 'MM-DD-YYYY', true).isValid()) {
      const formattedDate = util.formatDate(date); //backend will accept mm/dd/yyyy
      const normalValue = !formattedDate ? "" : formattedDate;

      setFieldTouched(name, true);

      //need to delete error else yup error val blips before valid
      deleteError(errors, name);
      setValueParentOrLocal(name, normalValue, setFieldValue, setFormValueCallback);
      setInvalidDateWarning("");

      handlePS(formattedDate);
      if (isQuestionsContext) {
        validateQuestionSimple(name, formattedDate);
      }
    }
    else {
      //if we fail the regex test, clear the date and inform user
      setDate("");
      setInvalidDateWarning(
        "The date entered was invalid. Please try entering the date again in the specified format (mm/dd/yyyy)."
      );
      if (isQuestionsContext) {
        validateQuestionSimple(name, "");
      }
    }
  };

  const handleChangeCalendar = (date) => {
    if (moment(date).isValid()) {
      setInvalidDate("");
      handleCheckDateLessThanAIs(util.formatDate(date));
      setDate(date);
      handleBlur(date);
    }
  };

  function getAIMinDate() {
    let aiMinDate = moment().add(1, 'days').valueOf();
    if (isAdmin) {
      aiMinDate = moment().valueOf();
    }
    return aiMinDate;
  }

  //#endregion functions & actions

  //#region dateFieldLabel

  let input_class_val_css;
  let validationState;
  let label_class_interpreted;

  if (hasError && touched[name]) {
    input_class_val_css = "error-border rounded-0 ";
    validationState = "error";
    label_class_interpreted += " error-msg ";
    if (isQuestionsContext && storedInvalidFields && util.isNotDuplicateValidation(name, storedInvalidFields)) {
      validateQuestionSimple(name, value);
    }
  }
  else {
    input_class_val_css = "";
    validationState = "";
    label_class_interpreted = "";
  }

  label_class_interpreted += label_css;

  //need this for programmatic date setting in PremInd, e.g. Plan's acceptedDate
  if ((!dateValue || dateValue === "") && defaultValue && value) {
    //if there's no dateValue, we must have set it outside and it needs to be triggered without UI event
    setDate(new Date(value));
    setTimeout(function () {
      handleBlur(new Date(value));
    }, 400);
  }
  else if (
    dateValue &&
    dateValue !== "" &&
    !defaultValue &&
    !value &&
    (util.isObjEmpty(psReady) || (psReady && psReady.value === undefined))
  ) {
    //if there's a dateValue but no value or default, it must be bad persisted data, needs to be cleared
    setDate("");
    setTimeout(function () {
      handleBlur(undefined);
    }, 400);
  }
  else if (
    psReady.value &&
    psReady.value !== undefined &&
    util.formatDate(psReady.value) !== util.formatDate(dateValue)
  ) {
    setDate(new Date(psReady.value));
  }

  let label_text = label ? label : "";
  if (psReady && psReady.setLabelValue) {
    label_text = psReady.setLabelValue;
  }

  const labelProps = {
    helptext,
    id,
    label_class_interpreted,
    label_prefix,
    label_text,
    popover_class,
    popover_placement,
    type,
  };

  //#endregion dateFieldLabel


  useLayoutEffect(() => {
    if (!isQuestionsContext) {
      //clear these states so field doesn't retain error info 
      //when nav'ing from questions to non-questions context
      setInvalidDate("");
      setInvalidDateWarning("");
    }
    //intentionally set to emppty array to ensure it only fires once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      id="date_field_main_container_1"
      className={label_text ? "" : "no_label"}
      key={id}
      tabIndex="1"
    >
      <Field
        id="date_field_wrapper_1"
        className={util.interpretOffsetClassContext(label_text, label_prefix, columnOffset, "")}
        component={FieldWrapper}
        name={"date-" + name}
      >
        {label_text && label_location === enums.labelLocation.top && (
          <Row id="date_field_row_1">
            <Col id="date_field_col_1" xs={"12"}>
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        <Row id="date_field_row_2" className={validationState}>
          {label_text && label_location === enums.labelLocation.left && (
            <Col id="date_field_col_2">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}

          <Col
            id="date_field_col_3"
            className={label_text ? "has_label" : ""}
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}
          >
            <div id="date_field_div_1" className={"dateField input-group d-flex justify-content-start expandDateInput"}>
              {(disabled || questions.pageUrl === "PremInd") && (
                //disabled fields don't use selected
                //and premInd date fields are not allowing me to use the traditional
                //psReady to show the new value -- this is the solution
                <Field component={FieldWrapper} name={"datefield-" + name} columnOffset={columnOffset}>
                  <DatePicker
                    className={"form-control roundAppend " + input_class_val_css}
                    dateFormat="MM/dd/yyyy"
                    disabled={true}
                    id={id}
                    onBlur={(e) => handleBlur(e.target.value)}
                    onChange={handleChangeCalendar}
                    openToDate={dateValue}
                    placeholderText={placeholder}
                    readOnly={disabled}
                    value={values[name]}
                    autoComplete={"none"}
                    ref={_dateField}
                    showYearDropdown
                    showPopperArrow={false}
                    yearDropdownItemNumber={3}
                    scrollableYearDropdown
                    minDate={minDate ? minDate : null}
                  />
                </Field>
              )}
              {!disabled && questions.pageUrl !== "PremInd" && (
                <Field component={FieldWrapper} name={"datefield-" + name} columnOffset={columnOffset}>
                  <DatePicker
                    className={"form-control roundAppend " + input_class_val_css}
                    dateFormat={"MM/dd/yyyy"}
                    disabled={
                      psReady && psReady.disableInput
                        ? psReady.disableInput
                        : psReady && psReady.setValueAndDisableInput
                          ? psReady.setValueAndDisableInput
                          : disabled
                    }
                    id={id}
                    onBlur={(e) => handleBlur(e.target.value)}
                    onChange={handleChangeCalendar}
                    openToDate={dateValue}
                    placeholderText={placeholder}
                    readOnly={disabled}
                    selected={dateValue}
                    autoComplete={"none"}
                    ref={_dateField}
                    showYearDropdown
                    showPopperArrow={false}
                    yearDropdownItemNumber={3}
                    scrollableYearDropdown
                    minDate={newMinDate ? newMinDate : null}
                  />
                </Field>
              )}
              <div
                id="date_field_div_2"
                className={psReady && psReady.disableInput ? "input-group-append disabledGray" : "input-group-append"}
              >
                <span id="date_field_span_1" className={"input-group-text"}>
                  <i className={"fa fa-calendar"} aria-hidden="true" />
                </span>
              </div>
            </div>
          </Col>

          {label_text && label_location === enums.labelLocation.right && (
            <Col id="date_field_col_4">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          )}
        </Row>

        {label_text && label_location === enums.labelLocation.bottom && (
          <Row id="date_field_row_3">
            <Col id="date_field_col_5">
              <LabelCommon ref={fieldsRef} {...labelProps} />
            </Col>
          </Row>
        )}

        {!isQuestionsContext && (
          // report yup errors - outside of questions
          <Row id="date_field_row_4">
            <Col id="date_field_col_6">
              <ErrorMessage errors={errors[name]} touched={touched[name]} />
            </Col>
          </Row>
        )}

        <Row id="date_field_row_5">
          <Col
            id="date_field_col_7"
            xs={static_col_breakpoint ? static_col_breakpoint : "12"}
            sm={static_col_breakpoint ? static_col_breakpoint : "12"}
            md={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "9" : "12"}
            lg={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "6" : "12"}
            xl={static_col_breakpoint ? static_col_breakpoint : isQuestionsContext ? "4" : "12"}>
            <NestedFieldAlert psReady={psReady} invalidDateWarning={invalidDateWarning} touched={touched[name]} />
          </Col>
        </Row>
      </Field>
    </Container>
  );
};

export default DateField;
