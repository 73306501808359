import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Button, Alert } from "react-bootstrap";
import { default as FormInputs } from "../../../../Utilities/Inputs/FormGroupsWrapper";
import util from "../../../../Utilities/Functions/Functions";
import enums from "../../../../Utilities/Enums/Enums";
import * as premIndHelper from "../../../QuestionHelpers/PremIndHelper";
import * as questionsActions from "../../../QuestionsActions";

export default function CustomQuestion(componentName, app, questions, formValues, questionData) {

  //use switch(true) instead of switch(method) so we can make program agnostic case tests with includes
  switch (true) {
    case componentName?.includes(questions.pageUrl + "_AddRepeatingItem") || componentName?.includes(questions.pageUrl + "s_AddRepeatingItem"):
      if (!questions.hideAllInputs) {
        return (
          <AddRepeatingItemButton
            app={app}
            buttonText={questionData.questionText}
            columnOffset={questionData.columnOffset}
            parentCardSetLookup={questionData.parentLookupCode}
            questions={questions}
            formValues={formValues}
          />
        );
      }
      else {
        break;
      }
    case componentName?.includes(questions.pageUrl + "_WCQuoteIndication"):
      if (!questions.hideAllInputs) {
        return (
          <WCQuoteIndication
            app={app}
            parentCardSetLookup={questionData.parentLookupCode}
            questions={questions}
            formValues={formValues}
          />
        );
      }
      else {
        break;
      }
    case componentName === "PremInd_CCChargeAmount":
      if (!questions.hideAllInputs) {
        return <CCChargeAmount app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    case componentName === "PremInd_ECheckQuote":
      if (!questions.hideAllInputs) {
        return <ECheckQuote app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    case componentName === "PremInd_FinalQuote":
      if (!questions.hideAllInputs) {
        return <PremQuoteAndLetter app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    case componentName === "PremInd_BrokerInfo":
      if (!questions.hideAllInputs) {
        return <BrokerInfo app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    case componentName === "PremInd_PremFinanceQuote":
      if (!questions.hideAllInputs) {
        return <PremFinanceQuote app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    case componentName === "PremInd_PremFinanceIPFSAddress":
      if (!questions.hideAllInputs) {
        return <PremFinanceIPFSAddress app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    case componentName === "PremInd_RequiresReviewNotice":
      if (questions.isReviewDependentPurchase) {
        return <RequiresReviewNotice />;
      }
      else {
        break;
      }
    case componentName === "PremInd_SLTermsAndConditions":
      if (!questions.hideAllInputs) {
        return <SLPremTermsAndConditions app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }

    //***full page components***/
    case componentName === "PremInd_QuestionPageErrors":
      if (questions.pageUrl === "PremInd" && !questions.isCompleteSubmission) {
        return <QuestionPageErrors app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    case componentName === "PremInd_QuestionSubmissionErrors":
      if (questions.pageUrl === "PremInd" && questions.isCompleteSubmission && questions.submissionValidationMsg.length > 0) {
        return <QuestionSubmissionErrors app={app} questions={questions} formValues={formValues} />;
      }
      else {
        break;
      }
    default:
      //TODO: Add an error case here?
      break;
  }
}

const formattedQuote = (app, premQuote) => {
  return (
    <div id="formatted_quote_main_div_1" className={"quote"}>
      <span id="formatted_quote_span_1" className={"dollar d-inline-flex align-self-center"}>$ </span>
      <span id="formatted_quote_span_2" className={"premPopulated"}>
        {premQuote ? premQuote.toString().replace(util.quoteFormatRegex.recipe, ",") : ""}
      </span>
    </div>
  );
};

const AddRepeatingItemButton = (props) => {
  const dispatch = useDispatch();

  const addNewRepeatingCardToSet = useCallback(
    (parentCardSetLookup, formValues, cardToCopy) =>
      dispatch(questionsActions.addNewRepeatingCardToSet(parentCardSetLookup, formValues, cardToCopy)),
    [dispatch]
  );

  return (
    <Row id="add_repeating_item_button_row_1" className={props.columnOffset ? "offset_" + props.columnOffset : ""}>
      <Col id="add_repeating_item_button_col_1" xs={"12"} md={"6"} lg={"4"}>
        <Button
          id="add_repeating_item_button_btn_1"
          onClick={() => addNewRepeatingCardToSet(props.parentCardSetLookup, props.formValues, null)}
          outline={"true"}
          variant={"outline-primary"}
          size="lg"
          className="w-100 addPageNameBtn"
        >
          {props.buttonText ? props.buttonText : "Add " + props.questions.navigationPage}
        </Button>
      </Col>
    </Row>
  );
};

const CCChargeAmount = (props) => {
  const creditIsSelected = props.formValues && props.formValues["PremInd_Credit"] === "TRUE" ? true : false;

  const formattedCCChargeAmount = () => {
    return (
      <div id="cc_charge_amount_main_div_1" className={creditIsSelected ? "parentCharge" : "d-none"}>
        <Col id="cc_charge_amount_col_1" className="text-right font18">
          Your card will be charged
        </Col>
        <Col id="cc_charge_amount_col_2" className="text-right">{formattedQuote(props.app, props.questions.premQuoteMinusCommission)}</Col>
      </div>
    );
  };

  return <div className={"form-group"}>{formattedCCChargeAmount()}</div>;
};

const ECheckQuote = (props) => {
  const eCheckIsSelected = props.formValues && props.formValues["PremInd_ECheck"] === "TRUE" ? true : false;

  let eCheckQuoteQuestion = props.questions.questions.find(q => q.lookupCode === "PremInd_ECheckQuote");

  const eCheckQuote = () => {
    return (
      <div id="echeck_quote_div_1" className={eCheckIsSelected ? "echeck" : "d-none"}>
        {/* <div className="d-inline-flex align-items-center"> */}
        <Col id="echeck_quote_col_1">
          <FormInputs
            id="eCheckHeader"
            name="eCheckHeader"
            label={"Amount to be debited"}
            label_prefix={"1"}
            type={enums.fieldTypes.header}
          />
        </Col>
        <Col id="echeck_quote_col_2" className="quote">
          {formattedQuote(props.app, props.questions.premQuoteMinusCommission)}
        </Col>
        {/* </div> */}
        <div id="echeck_quote_div_2">
          <Col id="echeck_quote_col_3" className={"font-italic subTitle"}>
            Please enter bank information. Your personal banking information is secure and is not stored by our company.
          </Col>
        </div>
      </div>
    );
  };

  return <div>{eCheckQuote()}</div>;
};

const PremFinanceIPFSAddress = (props) => {
  const premFinanceIsSelected = props.formValues && props.formValues["PremInd_PremFinance"] === "TRUE" ? true : false;

  const PremFinanceIPFSAddress = () => {
    return (
      <div id="prem_finance_ipfs_div_1" className={premFinanceIsSelected ? "mt-30" : "hide"}>
        <Row id="prem_finance_ipfs_row_1">
          <Col id="prem_finance_ipfs_col_1">
            <strong>IPFS</strong> <br />
            301 West 11th St, 4th Floor <br />
            Kansas City, MO 64141-6316 <br />
          </Col>
        </Row>
      </div>
    );
  };

  return <div>{PremFinanceIPFSAddress()}</div>;
};

const BrokerInfo = (props) => {
  const userIsBroker = props.app.user.entityTypeCode === enums.role.broker;

  const BrokerInfo = () => {
    return (
      <div id="broker_info_div_1" className={userIsBroker ? "" : "hide"}>
        <ul id="broker_info_ol_1">
          <li id="broker_info_li_1">
            <span id="broker_info_span_1">Mail-in Check</span> - This binds coverage immediately. Your check must be received by our office within 10 days.
            An invoice is included with the policy documents for your convenience.
          </li>
          <li id="broker_info_li_2">
            <span id="broker_info_span_2">eCheck</span> - This allows you to pay by electronic check and print your policy immediately.
          </li>
          <li id="broker_info_li_3">
            <span id="broker_info_span_3">Credit / Debit</span> - This option allows you to pay by credit or debit card and print your policy immediately.
          </li>
        </ul>
        <div id="broker_info_div_2">
          Policies must be purchased with an <span id="broker_info_span_4">agency</span> check or <span id="broker_info_span_5">agency</span> credit card. You will only be charged the net amount. Please collect the full amount from your client and retain your commission.
        </div>
        <div id="broker_info_div_3">
          Net Amount = Total Cost - Commission
        </div>
        <div id="broker_info_div_4">
          By purchasing this insurance, you are agreeing to the terms & conditions displayed within the Final Quote Letter above and are electronically signing this agreement.
        </div>
        <div id="broker_info_div_5">
          Your agency owes: <span id="broker_info_span_5">${props.questions.premQuoteMinusCommission}</span>
        </div>
      </div>
    );
  };

  return <div>{BrokerInfo()}</div>
}

const PremFinanceQuote = (props) => {
  const premFinanceIsSelected = props.formValues && props.formValues["PremInd_PremFinance"] === "TRUE" ? true : false;

  const PremFinanceQuote = () => {
    return (
      <div id="prem_finance_quote_div_1" className={premFinanceIsSelected ? "" : "hide"}>
        <div id="prem_finance_quote_div_2" className={"font-italic subTitle"}>
          A downpayment by electronic check (eCheck) is required to complete your purchase and print your policy
          documents. Your personal banking information is secure and is not stored by our company.
        </div>
        <div id="prem_finance_quote_div_3">
          Required Downpayment
          {formattedQuote(props.app, props.questions.downPayment)}
        </div>
      </div>
    );
  };

  return <div>{PremFinanceQuote()}</div>;
};

const PremQuoteAndLetter = (props) => {
  //dispatch actions
  const dispatch = useDispatch();

  const hasSelectedPayment = premIndHelper.hasSelectedPaymentType(props.formValues);

  const finalQuoteLetterPreCheck = (submissionID) => {
    finalQuoteLetter(submissionID);
  };

  const finalQuoteLetter = useCallback((submissionID) => dispatch(questionsActions.finalQuoteLetter(submissionID)), [
    dispatch,
  ]);

  const toggleEmailQuoteModal = useCallback(() => dispatch(questionsActions.toggleEmailQuoteModal()), [dispatch]);

  const formattedPremQuote = (props) => {
    return (
      <div id="prem_quote_and_letter_main_div_1" className={hasSelectedPayment ? "d-none" : ""}>
        {!props.questions.hasNoPremium && !props.questions.hasNegativeQuote && (
          <span id="prem_quote_and_letter_span_1">
            <Col id="prem_quote_and_letter_col_1" className={"selectPayQuote d-flex justify-content-start"}>
              {formattedQuote(props.app, props.questions.premQuote)}
            </Col>
            <Col id="prem_quote_and_letter_col_2" className={props.questions.hasNoPremium ? "d-flex justify-content-start" : "d-flex justify-content-start"}>
              <div id="prem_quote_and_letter_div_1">
                Review:{" "}
                <span
                  id="prem_quote_and_letter_span_2"
                  className={"link"}
                  onClick={() => finalQuoteLetterPreCheck(props.questions.submissionID)}
                >
                  Final Quote Letter
                </span>{" "}
                |{" "}
                <span
                  id="prem_quote_and_letter_span_3"
                  className={"link"}
                  onClick={() => toggleEmailQuoteModal()}
                >
                  Email Quote Letter
                </span>
              </div>
            </Col>
          </span>
        )}
        {props.questions.hasNoPremium && !props.questions.isWorkersCompOnly && (
          <span id="prem_quote_and_letter_span_4">
            <Col id="prem_quote_and_letter_col_3" className="d-flex justify-content-center">
              <div id="prem_quote_and_letter_div_2" className={"pageTitle"}>Almost Done!</div>
            </Col>
            {/* <Col id="prem_quote_and_letter_col_4">
              <div id="prem_quote_and_letter_div_3" className={"inputTitle"}>Complete Your Change</div>
            </Col> */}
            <Col id="prem_quote_and_letter_col_5">
              <FormInputs
                type={enums.fieldTypes.alert}
                id={"hasNoPremiumAlert"}
                label={"Click Finish below and RVNA will begin processing the changes to your policy."}
                label_class={"primary"} //TODO: Add this "Plus" to DB class
              />
            </Col>
          </span>
        )}
      </div>
    );
  };

  return <div>{formattedPremQuote(props)}</div>;
};

const QuestionPageErrors = (props) => {
  //dispatch actions
  const dispatch = useDispatch();

  const questions = props.questions;

  //local
  const handleNavigation = (pageUrl, pageName) => {
    navigate(questions, null, pageUrl, pageName, true, false);
  };

  const navigate = useCallback(
    (questions, values, pageUrl, pageName, withoutValidation, saveAndExit) =>
      dispatch(questionsActions.navigate(questions, values, pageUrl, pageName, withoutValidation, saveAndExit)),
    [dispatch]
  );

  let pages;
  let i = 0;
  const strapPage = (page, i) => {
    return (
      <li
        key={i}
        className="d-flex align-items-center"
        onClick={() => handleNavigation(page.pageUrl, page.pageDisplayName)}
      >
        <i className={"fa fa-circle"} aria-hidden="true"></i>
        {page.pageDisplayName}: <span className={"link"}>Complete this section now</span>{" "}
        <i className={"fa fa-caret-right"} aria-hidden="true"></i>
      </li>
    );
  };

  if (questions.invalidNavigationPages && questions.invalidNavigationPages.length > 0) {
    pages = questions.invalidNavigationPages.reduce(function (result, page) {
      result.push(strapPage(page, i));
      i++;
      return result;
    }, []);
  }

  const errors = () => {
    return (
      <div id="question_page_errors_main_div_1" className={"questionErrors"}>
        <Row id="question_page_errors_row_1">
          <Col id="question_page_errors_col_1" className="text-center">
            <h1 id="question_page_errors_header_1">Almost there.</h1>
          </Col>
        </Row>
        <Row id="question_page_errors_row_2">
          <Col id="question_page_errors_col_2">
            <Alert id="question_page_errors_alert_1" variant={"danger"}>
              Application Incomplete: It looks like some information is either incomplete or missing on your application.
              Please click on the link(s) to the sections below to complete what’s missing.
            </Alert>
          </Col>
        </Row>
        <ul id="question_page_errors_ul_1">{pages}</ul>
        <Row id="question_page_errors_row_3">
          <Col id="question_page_errors_col_3" className="font-italic">
            Any person who, with intent to defraud or knowing that he/she is facilitating a fraud against an insurer,
            submits an application or files a claim containing a false or deceptive statement is guilty of insurance
            fraud, a Federal Offense.
          </Col>
        </Row>
      </div>
    );
  };

  return <div>{errors()}</div>;
};

const QuestionSubmissionErrors = (props) => {
  //dispatch actions
  const dispatch = useDispatch();

  //store
  const questions = props.questions;

  //local
  const handleNavigation = (pageUrl, pageName) => {
    navigate(questions, null, pageUrl, pageName, true, false);
  };

  const navigate = useCallback(
    (questions, values, pageUrl, pageName, withoutValidation, saveAndExit) =>
      dispatch(questionsActions.navigate(questions, values, pageUrl, pageName, withoutValidation, saveAndExit)),
    [dispatch]
  );

  const buildMsgItems = (messages) => {
    let listItems;
    let i = 0;
    const strapMessage = (message, i) => {
      return (
        <li key={i} className="d-flex align-items-center">
          <i className={"fa fa-circle"} aria-hidden="true" />
          {message}
        </li>
      );
    };
    if (messages) {
      listItems = messages.reduce(function (result, message) {
        result.push(strapMessage(message, i));
        i++;
        return result;
      }, []);
    }
    return listItems;
  };

  let pages;
  const strapPage = (page) => {
    return (
      <div id={page.pageName}>
        <div>
          <h4>{page.pageName}</h4>
        </div>
        <ul>{buildMsgItems(page.validationMsg)}</ul>
        <div className="d-flex align-items-center" onClick={() => handleNavigation(page.pageUrl, page.pageName)}>
          <span>Review your answers now</span>
          <i className={"fa fa-caret-right"} aria-hidden="true" />
        </div>
      </div>
    );
  };

  if (questions.validationMsg) {
    pages = questions.validationMsg.reduce(function (result, page) {
      result.push(strapPage(page));
      return result;
    }, []);
  }

  const errors = () => {
    return (
      <div id="question_submission_errors_main_div_1" className={"questionErrors"}>
        <Row id="question_submission_errors_row_1">
          <Col id="question_submission_errors_col_1" className="text-center">
            <h1 id="question_submission_errors_header_1">Houston, we have a problem.</h1>
          </Col>
        </Row>
        <Row id="question_submission_errors_row_2">
          <Col id="question_submission_errors_col_2">
            <h3 id="question_submission_errors_header_2">
              <strong>Why am I seeing this message?</strong>
            </h3>
            <Alert id="question_submission_errors_alert_1" variant={"danger"}>
              Based on your answers R.V. Nuccio & Associates is unable to provide insurance to you at this time.
              If you feel this determination has been made in error or you feel your answers were incorrect, please
              review your answers using the links below or contact us at 1-800-364-2433, Monday - Friday 5:30AM - 5:00PM
              Pacific Time. Our insurance specialists will be happy to assist you.
            </Alert>
          </Col>
        </Row>
        <Col id="question_submission_errors_col_3">{pages}</Col>
      </div>
    );
  };

  return <div>{errors()}</div>;
};

const SLPremTermsAndConditions = () => {
  const slPremTermsAndConditions = () => {
    return (
      <div id="sl_prem_terms_div_1">
        <Row id="sl_prem_terms_row_1">
          <Col id="sl_prem_terms_col_1">
            <h2 id="sl_prem_terms_header_1">The Insurance Company</h2>
          </Col>
        </Row>

        <Row id="sl_prem_terms_row_3">
          <Col id="sl_prem_terms_col_3">
            The insurance policy that you are applying to purchase is being issued by a surplus line insurer.
          </Col>
        </Row>


        <Row id="sl_prem_terms_row_2">
          <Col id="sl_prem_terms_col_2" className="mt-15">
            <h4 id="sl_prem_terms_header_2">The Policy</h4>
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_3">
          <Col id="sl_prem_terms_col_3">
            Surplus line insurance policies are sold by non-admitted carriers through licensed surplus line
            brokers. Other insurance agents and brokers must go to a licensed surplus line
            brokerage to access non-admitted carriers. When such companies are on the list of
            eligible surplus line insurers, they are regulated.
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_4">
          <Col id="sl_prem_terms_col_4" className="mt-15">
            <h4 id="sl_prem_terms_header_3">Non-Admitted Or Surplus Line</h4>
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_5">
          <Col id="sl_prem_terms_col_5">
            Non-admitted does not mean non-regulated. Non-admitted carriers on the approved list have been
            reviewed and approved by the Department of Insurance for surplus line insurance.
            Non-admitted carriers on the list are actually admitted insurance carriers in
            their state of domicile. Surplus lines have been written by non-admitted
            carriers since the 1800's, and generally are used when a risk is unusual,
            unusually large or when coverage is not generally available from other licensed
            carriers.
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_6">
          <Col id="sl_prem_terms_col_6" className="mt-15">
            <h4 id="sl_prem_terms_header_4">Solvency Regulations</h4>
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_6">
          <Col id="sl_prem_terms_col_6">
            Non-admitted insurers on the approved list must demonstrate their financial stability, reputation and
            integrity; maintain a minimum of $15 million in capital and surplus at all
            times (depending upon the state); have 3 years seasoning; have a valid license
            to transact insurance in their state of domicile; file financial information
            with the Department of Insurance and adhere to specific capitalization,
            investment and solvency standards established under the insurance code.
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_7">
          <Col id="sl_prem_terms_col_7" className="mt-15">
            <h4 id="sl_prem_terms_header_5">Non-Admitted Means</h4>
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_8">
          <Col id="sl_prem_terms_col_8" xs={"12"}>
            1. Insurance carriers not licensed by the state (also called surplus line carriers).
          </Col>
          <Col id="sl_prem_terms_col_9" xs={"12 mt-15"}>
            2. Carriers on the approved list are actually admitted insurance carriers, licensed in a state of domicile.
          </Col>
          <Col id="sl_prem_terms_col_10" xs={"12 mt-15"}>
            3. Carriers that must meet strict surplus line laws and regulations in order to provide insurance to businesses and residents.
          </Col>
          <Col id="sl_prem_terms_col_11" xs={"12 mt-15"}>
            4. Carriers regulated by their state of domicile, including stringent requirements regarding reputation and integrity, capitalization and solvency, licensing and business practices.
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_9">
          <Col id="sl_prem_terms_col_12" className="mt-15">
            <h4 id="sl_prem_terms_header_6">Non-Admitted Or Surplus Line</h4>
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_10">
          <Col id="sl_prem_terms_col_13">
            Non-admitted does not mean non-regulated. Non-admitted carriers on the approved list have been
            reviewed and approved by the Department of Insurance for surplus line insurance.
            Non-admitted carriers on the list are actually admitted insurance carriers in
            their state of domicile. Surplus lines have been written by non-admitted
            carriers since the 1800's, and generally are used when a risk is unusual,
            unusually large or when coverage is not generally available from other licensed
            carriers.
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_11">
          <Col id="sl_prem_terms_col_14" className="mt-15">
            <h4 id="sl_prem_terms_header_7">D1 Statement</h4>
          </Col>
        </Row>
        <Row id="sl_prem_terms_row_12">
          <Col id="sl_prem_terms_col_15" xs={"12"}>
            1. The insurance policy that you are applying to purchase is being issued by an insurer that is not licensed by the State of California. These companies are called 'nonadmitted' or 'surplus line' insurers.
          </Col>
          <Col id="sl_prem_terms_col_16" xs={"12 mt-15"}>
            2. The insurer is not subject to the financial solvency regulation and enforcement that apply to California licensed insurers.
          </Col>
          <Col id="sl_prem_terms_col_17" xs={"12 mt-15"}>
            3. The insurer does not participate in any of the insurance guarantee funds created by California law. Therefore, these funds will not pay your claims or protect your assets if the insurer becomes insolvent and is unable to make payments as promised.
          </Col>
          <Col id="sl_prem_terms_col_18" xs={"12 mt-15"}>
            4. The insurer should be licensed either as a foreign insurer in another state in the United States or as a non-United States (alien) insurer. You should ask questions of your insurance agent, broker, or 'surplus line' broker or contact the
            California Department of Insurance at the toll-free number 1-800-927-4357 or internet website www.insurance.ca.gov. Ask whether or not the insurer is licensed as a foreign or non-United States (alien) insurer and for additional information about the insurer.
            You may also visit the NAIC's internet website at www.naic.org. The NAIC, the National Association of Insurance Commissioners, is the regulatory support organization created and governed by the chief insurance regulators in the United States.
          </Col>
          <Col id="sl_prem_terms_col_19" xs={"12 mt-15"}>
            5. Foreign insurers should be licensed by a state in the United States and you may contact that state's department of insurance to obtain more information about that insurer. You can find a link to each state from this NAIC internet website: https://naic.org/state_web_map.htm.
          </Col>
          <Col id="sl_prem_terms_col_20" xs={"12 mt-15"}>
            6. For non-United States (alien) insurers, the insurer should be licensed by a country outside of the United States and should be on the NAIC's International Insurers Department (IID) listing of approved nonadmitted non-United States insurers.
            Ask your agent, broker, or 'surplus line' broker to obtain more information about that insurer.
          </Col>
          <Col id="sl_prem_terms_col_21" xs={"12 mt-15"}>
            7. California maintains a 'List of Approved Surplus Line Insurers (LASLI).'' Ask your agent or broker if the insurer is on that list, or view that list at the internet website of the California Department of Insurance: www.insurance.ca.gov/01-consumers/120-company/07-lasli/lasli.cfm.
          </Col>
          <Col id="sl_prem_terms_col_22" xs={"12 mt-15"}>
            8. If you, as the applicant, required that the insurance policy you have purchased be effective immediately, either because existing coverage was going to lapse within two business days or because you were required to have coverage within two business days, and you did not receive this
            disclosure form and a request for your signature until after coverage became effective, you have the right to cancel this policy within five days of receiving this disclosure. If you cancel coverage, the premium will be prorated and any broker's fee charged for this insurance will be returned to you.
          </Col>
        </Row>

      </div>
    );
  };

  return <div>{slPremTermsAndConditions()}</div>;
};

const RequiresReviewNotice = () => {
  const requiresReviewNotice = () => {
    return (
      <div id="requires_review_notice_main_div_1" className={"mt-30"}>
        <Row id="requires_review_notice_row_1">
          <Col id="requires_review_notice_col_1">
            <Alert id="requires_review_notice_alert_1" variant={"primary"}>
              <p>Your application must be submitted for review. Please click the Submit button below.</p>
              <p>Once you application has been reviewed, you will be contacted by our office. If you have any questions, please call us at 1-800-364-2433.</p>
            </Alert>
          </Col>
        </Row>
      </div>
    );
  }

  return <div>{requiresReviewNotice()}</div>;
};

const WCQuoteIndication = (props) => {
  const showWCQuoteIndication = props.formValues && props.questions
    && props.questions.quote && props.questions.hasWorkersCompCoverage
    ? true : false;

  const wcQuoteIndication = () => {
    return (
      <div id="wc_quote_indication_main_div_1" className={showWCQuoteIndication ? "" : "d-none"}>
        <Row id="wc_quote_indication_row_1">
          <Col id="wc_quote_indication_col_1">
            <Alert id="wc_quote_indication_alert_1" variant={"primary"}>
              Workers Compensation: The cost for Workers Compensation coverage is NOT included in this estimate.
              If you choose to purchase Workers Compensation coverage, you will be directly billed by mail by the insurance carrier.
            </Alert>
          </Col>
        </Row>
      </div>
    );
  };

  return <div>{wcQuoteIndication()}</div>;
};
